.homepage_term_select {
  border-radius: 8px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}

.homepage_chart_title {
  color: #ffffff;
  background: linear-gradient(
    90.14deg,
    #38c3a8 0%,
    #38c3a8 51.2%,
    rgba(84, 33, 183, 0) 97.34%
  );
  margin-top: 20px !important;
  font-size: 26px !important;
  line-height: 40px;
  height: 40px;
}

.homepage_month_span {
  font-size: 24px;
  color: #38c3a8;
  border-bottom: 1px #38c3a8 solid;
}

.unitily_select {
  border-radius: 28px !important;
}

.total_price {
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding: 24px 32px;
  box-shadow: 5px 5px 5px #00000015;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 768px) {
  .select_month_title {
    flex-direction: column !important;
  }

  .select_month {
    justify-content: center !important;
  }

  .total_price {
    padding: 40px 20px;
    margin-bottom: 20px;
  }
}
